import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import '@fortawesome/fontawesome-svg-core/styles.css'

const IndexPage = () => (
  <Layout>
    <SEO title="About us" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Camping Silveira</h1>
          <h2 class="subtitle">Welcome nature and quietness lover!</h2>
        </div>
      </div>
    </section>
    <section class="hero has-background-info">
      <div class="hero-body">
        <div class="container">
          <h2 class="subtitle has-text-white has-text-weight-bold">
            Open between May 1st and September 15th
          </h2>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column is-1" />
        <div class="column is-6">
          <div class="content">
            <p>
              Between sea and mountains, Silveira is a rural campsite without
              animation, without swimming-pool or locations, 100 km distant from
              Lisbon and 3 km from Alcobaça.
            </p>
            <p>Silveira is an ancient farm with 8 hectares</p>
            <p>
              The campsite has been settled in an area of forest (mainly
              eucalyptus and cork oaks).
            </p>
            <p>
              The 19 plots are separated by the natural vegetation. We have a
              maximum capacity of 60 people and we are open from May 1st until
              September 15th.
            </p>
            <p>At the disposal of the campers:</p>
            <ul type="1">
              <li>Washing machine</li>
              <li>Barbecues</li>
              <li>Tables in a covered area</li>
              <li>Refrigerator and freezer</li>
              <li>Ping-pong table</li>
              <li>Badminton area</li>
              <li>Small library and some toys</li>
              <li>
                <strong>
                  Seven hectares of nature, where you can walk and children can
                  play
                </strong>
              </li>
              <li>Free Wi-Fi acces point</li>
            </ul>
          </div>
        </div>
        <div class="column">
          <figure class="image" style={{ "margin-bottom": "1rem" }}>
            <img src="/img/photos/convivio_1_m.png" alt="alt" />
            <p class="is-size-7" style={{ "text-align": "center" }}>
              Campsite facilities
            </p>
          </figure>
          <figure class="image" style={{ "margin-bottom": "1rem" }}>
            <img src="/img/photos/eg_plot_1_m.png" alt="alt" />
            <p class="is-size-7" style={{ "text-align": "center" }}>
              Campsite plot example
            </p>
          </figure>
          <figure class="image" style={{ "margin-bottom": "1rem" }}>
            <img src="/img/photos/nazare_1_m.png" alt="alt" />
            <p class="is-size-7" style={{ "text-align": "center" }}>
              Nazaré beach (15km from the campsite)
            </p>
          </figure>
        </div>
        <div class="column is-1" />
      </div>
    </div>
  </Layout>
)

export default IndexPage
